@import './variables';
.router-container {
  overflow-x: hidden;
}

.page {
  background: $color-gray-dark;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0 !important;
  }

  &.full-height {
    min-height: 100vh;
    padding-top: $base-spacing;
  }

  &.top-page {
    background: $gray-gradient-light;
  }

  &.main {
    //background: $gray-gradient-light;
    // padding-top: $base-spacing;
    width: 100%;
    //overflow: hidden;
  }

  &.no-gradient {
    background: $color-gray-1;
  }

  .page-content {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    min-height: calc(100vh - 112px);
    background: transparent;

    .content {
      max-width: 1100px;
      width: 100%;
      margin: 0 auto;
      flex: 1 1 auto;
    }

    .slice {
      padding: $base-spacing * 2 $base-spacing;

      &.full-height-2 {
        min-height: calc(100vh - 80px);
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: space-between;

        @media (orientation: portrait) and (min-width: 700px) {
          min-height: fit-content;
        }
      }

      &.full-height {
        height: 100%;
        min-height: calc(100vh - #{$base-spacing} - 80px);

        justify-content: space-between;
      }

      &.full-width {
        padding-left: 0;
        padding-right: 0;
      }

      &.headless {
        padding-top: 0 !important;
      }

      &.bottomless {
        padding-bottom: 0 !important;
      }

      &.bigly-spacing {
        padding: $base-spacing * 3 $base-spacing;

        @media only screen and (min-width: 700px) {
          padding: $base-spacing * 5 $base-spacing;
        }
      }

      &.big-spacing,
      .big-spacing {
        padding: $base-spacing * 4 $base-spacing;

        @media only screen and (min-width: 700px) {
          padding: $base-spacing * 10 $base-spacing;
        }
      }

      &.primary {
        background: $color-primary;
      }

      &.lighter {
        background: $gray-gradient-1;
      }

      &.white {
        background: white;
        color: $color-gray-1;
      }
    }

    .content-splitter {
      flex: 1 1 auto;
      // height: 100%;
    }

    @media only screen and (max-width: 700px) {
      .content-splitter.with-phone {
        display: block;
        margin-top: $base-spacing;
      }
    }

    @media only screen and (min-width: 700px) {
      .content-splitter.with-phone {
        justify-content: center;

        .split-content {
          &:last-child {
            width: 38%;
            margin-right: 2%;
          }

          &:first-child {
            width: 60%;
          }
        }
      }
    }
  }

  app-site-header,
  app-site-footer {
    display: block;
    flex: 0 0 auto;
  }
}

.page-wrapper {
  position: relative;
}

.header {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.overlay {
  background: $color-primary;
  padding: $base-spacing;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-content {
  max-width: 560px;
  margin: 0 auto;
  text-align: center;
}

.content-splitter {
  display: flex;
  flex-wrap: wrap;

  &.reversed {
    @media only screen and (min-width: 700px) {
      flex-direction: row-reverse;
    }
  }

  .split-content {
    width: 100%;
    flex: 0 0 auto;

    @media only screen and (min-width: 700px) {
      width: 50%;
    }
  }

  &.split-70-30 {
    .split-content {
      @media only screen and (min-width: 700px) {
        &:first-child {
          width: 70%;
        }
        &:last-child {
          width: 30%;
        }
      }
    }
  }

  &.with-spacing {
    .split-content {
      @media only screen and (min-width: 700px) {
        &:first-child {
          margin-right: $base-spacing * 4;
          width: calc(50% - #{$base-spacing * 4});
        }
        &:last-child {
          margin-left: $base-spacing * 4;
          width: calc(50% - #{$base-spacing * 4});
        }
      }
    }

    &.reversed {
      .split-content {
        @media only screen and (min-width: 700px) {
          &:first-child {
            margin-left: $base-spacing * 4;
            margin-right: 0;
          }
          &:last-child {
            margin-right: $base-spacing * 4;
            margin-left: 0;
          }
        }
      }
    }

    &.split-60-40 {
      .split-content {
        @media only screen and (min-width: 700px) {
          &:first-child {
            width: calc(45% - #{$base-spacing * 4});
          }
          &:last-child {
            width: calc(55% - #{$base-spacing * 4});
          }
        }
      }
    }
  }

  &.split-3 {
    .split-content {
      @media only screen and (min-width: 700px) {
        width: 33.333%;
      }
    }
  }
}

.center-content-vertically {
  display: flex;
  flex-direction: column;
  justify-content: center;

  > * {
    display: block;
    flex: 0 0 auto;
  }
}

.center-content-vertically-on-desktop {
  @media only screen and (min-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      display: block;
      flex: 0 0 auto;
    }
  }
}

.overlay-title,
.page-title {
  font-size: 36px;
  line-height: 45px;
  font-weight: bold;
  color: $color-light;

  @media only screen and (min-width: 1024px) {
    font-size: 64px;
    line-height: 64px;
  }
}

.page-centered-title {
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: $base-spacing * 2;

  @media only screen and (min-width: 700px) {
    font-size: 32px;
  }
}

.page-sub-title {
  color: white;
  font-size: 32px;
  font-weight: bold;

  @media only screen and (min-width: 700px) {
    font-size: 40px;
  }
}

.overlay-text,
.page-text {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  margin: $base-spacing 0;
  color: $color-gray-4-light;

  @media only screen and (min-width: 700px) {
    font-size: 20px;
  }

  &.small {
    font-size: 14px;
    margin-top: 0;

    @media only screen and (min-width: 700px) {
      font-size: 16px;
    }
  }

  &.bottomless {
    margin-bottom: 0;
  }
}

.page-intro {
  &.center {
    max-width: 560px;
    margin: $base-spacing * 2 auto 0;
  }
  &.center-text-on-mobile {
    @media (max-width: 700px) {
      text-align: center;
    }
  }
}

.center {
  text-align: center;
}

.space-above-on-mobile {
  @media only screen and (max-width: 700px) {
    margin-top: $base-spacing;
  }
}

.feature {
  margin-top: $base-spacing;
  padding: 0 $base-spacing * 2;

  @media only screen and (max-width: 700px) {
    margin-bottom: $base-spacing * 2;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
  }

  img {
    margin-bottom: $base-spacing;
    height: 44px;
  }
}

.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  background: black;

  &.square {
    padding-bottom: 100%;
  }

  video {
    display: block;
  }
}

img.full-width {
  display: block;
  width: 100%;
  height: auto;
}

.page-claim {
  color: $color-primary;
  display: flex;
  align-items: center;
  font-size: 21px;
  margin-top: $base-spacing/2;

  @media only screen and (max-width: 700px) {
    justify-content: center;
    font-size: 17px;
  }

  &.bigger {
    font-size: 24px;
    @media only screen and (max-width: 700px) {
      font-size: 20px;
    }
  }

  &.center {
    justify-content: center;
  }

  span {
    display: block;
    flex: 0 0 auto;
    font-weight: bold;
    max-width: calc(100% - 35px);
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }

  img {
    display: block;
    flex: 0 0 auto;
    margin-right: $base-spacing/2;
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: $base-spacing/2 0;
    text-align: left;
    vertical-align: middle;
    width: 1px;

    &:last-of-type {
      padding-right: 0;
    }
  }

  td {
    border-top: 1px solid rgba(255, 255, 255, 0.25);
  }
}

.flex {
  display: flex;
  &.col {
    flex-direction: column;
    &.center {
      align-items: center;
    }
  }
  &.row {
    flex-direction: row;
    &.space-around {
      justify-content: space-around;
    }
    &.center {
      justify-content: center;
    }
    &.vcenter {
      align-items: center;
    }
  }
  &.wrap {
    flex-wrap: wrap;
  }
}

.card {
  background-color: white;
  border-radius: $border-radius;
  border: 1px solid $color-box-shadow;
  box-shadow: 5px 5px 10px $color-box-shadow;
  display: flex;
  color: $color-gray-1;
  padding: $base-spacing;
  max-width: 600px;
  margin: auto;

  > div {
    flex: 0 1 50%;
    justify-content: center;
  }

  .content {
    margin-left: $base-spacing/2;
    > * + * {
      margin-top: $base-spacing/2;
    }
  }

  .img-container {
    > img {
      width: 100%;
    }
  }
}

//NEW LAYOUT 2023
.content-title,
.content-subtitle {
  color: $color-light;
  text-align: center;
}

.content-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}

.content-subtitle {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}

.content-description {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  color: $color-gray-light;
}

@media only screen and (min-width: 768px) {
  .content-title {
    font-size: 42px;
    line-height: 54px;
  }

  .content-subtitle {
    font-size: 36px;
    line-height: 48px;
  }

  .content-description {
    font-size: 21px;
    line-height: 26px;
  }
}

@media only screen and (min-width: 1024px) {
  .content-title {
    font-size: 48px;
  }
}

.slice {
  &.small-margin-top {
    margin-top: 2 * $base-spacing;
  }

  &.small-margin-bottom {
    margin-bottom: 2 * $base-spacing;
  }

  &.middle-margin-top {
    margin-top: 4 * $base-spacing;
  }

  &.middle-margin-bottom {
    margin-bottom: 4 * $base-spacing;
  }

  &.big-margin-top {
    margin-top: 6 * $base-spacing;
  }

  &.big-margin-bottom {
    margin-bottom: 6 * $base-spacing;
  }

  &.no-margin-top {
    margin-top: 0 !important;
  }

  &.no-margin-bottom {
    margin-bottom: 0 !important;
  }

  @media only screen and (min-width: 1024px) {
    &.small-margin-top {
      margin-top: 4 * $base-spacing;
    }

    &.small-margin-bottom {
      margin-bottom: 4 * $base-spacing;
    }

    &.middle-margin-top {
      margin-top: 6 * $base-spacing;
    }

    &.middle-margin-bottom {
      margin-bottom: 6 * $base-spacing;
    }

    &.big-margin-top {
      margin-top: 8 * $base-spacing;
    }

    &.big-margin-bottom {
      margin-bottom: 8 * $base-spacing;
    }
  }
}
